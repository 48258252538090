<template>

<app-content :loading="true">

</app-content>

</template>

<script>

export default {

	created: function() {

		this.$store.commit('key', undefined)

		this.$nextTick(function() {
			
			window.location.reload()

		})

	}

}

</script>

<style scoped>

</style>